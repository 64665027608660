import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Maskless lithography equipment`}</h4>
    <p>{`Maskless lithography equipment draws patterns in a substrate using laser light, electrons, or ions. Unlike photolithography equipment, maskless lithography tools work without masks. This allows them to quickly and cheaply make new patterns, but slows down the process of drawing patterns into the wafer. As a result, maskless lithography tools can cost-effectively produce low-volume items like photomasks, but are are ill-suited to mass chip production.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      